<template>
  <div v-if="!!userFamilyName">
    <v-card-text
      class="pa-2"
    >
      <div>
        {{ $t('FamilyName') }}:
      </div>
      <div class="font-weight-bold">
        {{ userFamilyName }}
      </div>
    </v-card-text>
    <v-list>
      <template v-for="(item, index) in userFamilyMembers">
        <v-list-item
          :key="item.id_user"
        >
          <v-list-item-avatar>
            <v-img
              v-if="!!item.face_image_url"
              :src="item.face_image_url"
            ></v-img>
            <v-img
              v-else
              :src="require(`@/assets/images/avatars/${index % 9 + 1}.png`)"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.full_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="text--primary">
                {{ $t(typeMember(item.type_member)) }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider
          v-if="index < userFamilyMembers.length - 1"
          :key="`divider-${item.id_user}`"
          :inset="item.inset"
        ></v-divider>
      </template>
    </v-list>
  </div>
  <div v-else>
    <v-card-text
      class="pa-2"
    >
      {{ $t('NoFamilyMsg') }}
    </v-card-text>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { typeMember } from '@/utils/familyHelper'
import AccountService from '@/services/AccountService'

export default {
  setup() {
    const { router } = useRouter()
    const userId = router.currentRoute.params.id
    const userFamilyName = ref('')
    const userFamilyMembers = ref([])

    const getUserFamilyData = async () => {
      try {
        const resp = await AccountService.getUserFamily({
          id_user: userId,
        })

        userFamilyName.value = resp.data.data.name
        userFamilyMembers.value = resp.data.data.members
      } catch {}
    }

    onMounted(async () => {
      await getUserFamilyData()
    })

    return {
      userFamilyName,
      userFamilyMembers,
      typeMember,
    }
  },
}
</script>
